@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../chess-sandbox/lichess/lichess.scss';

:root {
  --ant-red-5: #ff4d4f;
  --ant-green-5: #73d13d;
  --ant-green-5-opacity-50: rgba(115, 209, 61, 0.5);
  --ant-blue-5: #40a9ff;
  --gray-200: #E4E6ED;
  --blue-400: #5D70FF;
  --blue-500: #4157FF;
  --blue-600: #3333D1;
  --blue-700: #2626A5;
  --blue-900: #11122C;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: #E4E6ED;
}
html {
  font-size: 16px;
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--blue-900);
  height: 100%;
  width: 100%;
}

a {
  color: var(--blue-400);
}
a:hover, a:active, a:focus {
  color: var(--blue-600);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-modal-mask {
  background: rgba(250, 250, 250, 0.9) !important;
}

.ant-btn-primary {
  height: 52px !important;
  padding: 8px 24px !important;
  background-color: #020246;
  &:hover {
    background-color: #020246 !important;
  }
}
svg {
  flex-shrink: 0;
}

.header-menu {
  .profile-box {
    display: none !important;
  }
  .ant-menu-item {
    margin: 0 16px;
    padding: 0 !important;
    &:after {
      left: 0 !important;
      right: 0 !important;
    }
    @media screen and (max-width: 640px) {
      margin: 0 6px;
    }
    @media screen and (max-width: 1024px) {
      margin: 0 8px;
    }
  }
}

.header-menu-overflow {
  .logout-dropdown {
    display: none !important;
  }
  ul {
    background-color: white;
    li {
      padding: 0 !important;
      height: auto !important;
      .ant-menu-title-content {
        width: 100%;
        height: 100%;
        display: flex;
      }
      a {
        padding: 0 16px;
        color: black;
        width: 100%;
        height: 100%;
        &:hover {
          background-color: #e7e7e7;
        }
      }

      .profile-box {
        .inline {
          padding: 0 16px !important;
        }
        &-logout:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }
}

.table-custom-options .ant-pagination-options {
  float: right;
}

.table-custom-options .ant-table-pagination {
  display: block;
}

.filter-cell {
  cursor: pointer;
}

.filter-cell:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ant-input-show-count-suffix {
  animation: append-animate 0.3s linear;
}

.ant-input-affix-wrapper {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  padding: 18px 15px;
  border-radius: 8px;
  border: 1px solid #e0e4ec;
  background: linear-gradient(0deg, #fafbfc 0%, #fafbfc 100%), #fff;
  &:focus {
    border-color: #5570f1 !important;
  }
  input {
    background: linear-gradient(0deg, #fafbfc 0%, #fafbfc 100%), #fff !important;
  }
}

@keyframes append-animate {
  from {
    /*transform: scale(0.8);*/
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.anticon {
  display: inline-flex !important;
}

.range-filter .ant-input-number-focused {
  box-shadow: none !important;
  border-color: #d9d9d9;
  outline: none;
}

.ant-upload-list-item-actions {
  display: flex;
}

.upload-form-item .ant-upload-list-item-image {
  object-fit: cover !important;
}

.video-modal .ant-modal-header {
  border: 0;
  padding: 0;
}

.video-modal .ant-upload,
.video-modal .ant-upload-list-item,
.video-modal .ant-upload-list-item-info {
  border-radius: 10px !important;
}

.upload-form-item.ant-form-item-has-error .ant-upload,
.upload-form-item.ant-form-item-has-error
  .ant-upload-list-item-list-type-picture-card.ant-upload-list-item-done {
  border-color: var(--ant-red-5) !important;
  color: var(--ant-red-5) !important;
}

.upload-form-item .ant-upload-list-item-info::before {
  display: block;
}

.upload-form-item .ant-upload-list-item {
  padding: 0 !important;
}

.upload-form-item .ant-upload-list-item-name {
  padding: 0 4px !important;
}

.upload-form-item:not(.ant-form-item-has-error)
  .ant-upload-list-item-list-type-picture-card.ant-upload-list-item-done,
.upload-form-item:not(.ant-form-item-has-error)
  .ant-upload-list-item-list-type-picture-card.ant-upload-list-item-undefined {
  border-width: 0;
}

.upload-form-item .ant-upload.ant-upload-btn {
  padding: 0 !important;
}
.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  margin: 0 !important;
}

@keyframes compressing {
  0% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  100% {
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.compressing {
  -webkit-animation: compressing 2s linear infinite;
  -moz-animation: compressing 2s linear infinite;
  -ms-animation: compressing 2s linear infinite;
  -o-animation: compressing 2s linear infinite;
  animation: compressing 2s linear infinite;
}

.shadow-filter {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 4px 0;
}

.ant-upload-wrapper.upload-border-none .ant-upload {
  border-style: none;
}

.custom-picture {
  .ant-form-item {
    max-width: 100%;
    overflow: hidden;
  }

  .ant-upload-list-item {
    &.ant-upload-list-item-done {
      overflow: hidden;
      border-radius: 10px;
      width: max-content !important;
      max-width: 100%;
      height: auto !important;
      border-style: none !important;
      margin: auto;

      .ant-upload-list-item-name {
        display: none;
      }

      .ant-upload-list-item-thumbnail {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        max-height: 100%;

        &:hover {
          filter: brightness(50%);
        }
      }

      .ant-upload-list-item-actions {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        place-content: center;
        place-items: center;

        &:hover {
          background-color: rgba(0, 0, 0, 0.7);

          svg {
            color: white;
          }
        }
      }
    }

    .ant-upload-list-item-image {
      min-height: 100px;
      max-height: 150px;
      width: auto !important;
      max-width: 100%;
    }
  }
}

.ant-tabs-nav:before {
  border-bottom-width: 0 !important;
}
.piece-uploads {
  .ant-upload-list-item.ant-upload-list-item-done {
    img {
      aspect-ratio: 1/1;
      width: 100% !important;
      min-height: auto;
    }
  }
}
.ant-table-thead {
  white-space: nowrap;
}

.card-table {
  thead {
    tr {
      th {
        padding: 37px 24px 24px 24px !important;
        border-bottom: none !important;
        color: #989eb7 !important;
        font-size: 14px;
        font-weight: 500;
        &:first-of-type {
          color: #11122c !important;
          font-size: 18px;
          font-weight: 700;
          border-top: none !important;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid #f2f2f2;
        padding: 20px 24px !important;
      }
      &:first-of-type {
        padding-top: 0 !important;
        border-top: none !important;
      }
    }
  }
}

// .ant-layout-sider,
// .ant-menu-dark,
// .ant-menu-inline {
//   background: #667080 !important;
//   flex: 0 0 266px !important;
//   max-width: 266px !important;
//   min-width: 266px !important;
//   width: 266px !important;
// }

// .ant-menu-inline {
//   margin-top: 73px;
//   padding: 16px !important;
// }

.mantine-Paper-root {
  overflow: visible;
}

.chapters-dropdown .ant-dropdown-menu {
  border: 1px solid #5D70FF;
  padding: 6px 0 !important;
}

.chapters-dropdown .ant-dropdown-menu-item {
  padding: 8px 16px !important;
}

.chapters-dropdown .ant-dropdown-arrow::after {
  border: 1px solid #5D70FF;
}

.mantine-TableHeadCell-Content-Labels {
  width: 100%;
}
.mantine-TableHeadCell-Content-Wrapper {
  width: 100%;
}

.ant-table-content::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

.ant-table-content::-webkit-scrollbar-track {
  background: white;
  /* color of the tracking area */
}

.ant-table-content::-webkit-scrollbar-thumb {
  background-color: #c8c6c6;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid white;
  /* creates padding around scroll thumb */
}

.mantine-Table-root td:not(.gradesTableRow) {
  border: none !important;
  background-color: white;
}


.react-datepicker__header {
  display: none;
}

.react-datepicker__time-box {
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; 
  }
  ::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 4px;
  }
  ::-webkit-scrollbar {
    width: 8px; 
    border-radius: 2px;
    border-radius: 4px;
  }  
}
.react-datepicker__time-list-item{
  font-size: 14px;
  font-family: "Inter", sans-serif !important;

}

.cg-wrap {
  height: 512px !important;
  position: relative;
  display: block;
  width: 512px;
  padding-bottom:0;
  cg-container {
    cg-board {
      background-repeat: no-repeat;
      background-size: 512px 512px;
    }
  }
  piece {
    width: 64px;
    height: 64px
  }
}

#chessWrapper {

  --cg-width: 512px !important;
  --cg-height: 512px !important;
}